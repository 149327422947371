export const CLIENT_ID =
  "1067944020646-g78cb2cbdo78kf81e3ujedn6t0jn36lm.apps.googleusercontent.com";
export const CLIENT_SECRET = "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";
export const REDIRECT_URL = window.location.href.substring(
  0,
  window.location.href.length - 1
);
export const SPREADSHEET_ID = "1uQC1GvcbNknhdO-put8VAqQZdWRDnHYDh2YW7LlSrXM";
export const BASE_URL = `https://sheets.googleapis.com/v4/spreadsheets/${SPREADSHEET_ID}/values`;
export const KUNTAO_ATTENDANCE_COL = "F";
export const KALI_ATTENDANCE_COL = "J";
export const LAST_LOGIN_COL = "K";
export const STUDENTS_RANGE = `A2:${LAST_LOGIN_COL}`;
