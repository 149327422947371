import { FC, ReactNode } from "react";

interface Props {
  children: ReactNode;
  onClick: () => void;
  isActive: boolean;
}

const InvertedToggle: FC<Props> = ({ children, onClick, isActive }) => {
  return (
    <div
      onClick={() => onClick()}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",

        backgroundColor: isActive ? "black" : "white",
        color: isActive ? "white" : "black",
        border: "1px solid black",
        cursor: "pointer",
        width: 200,
        padding: 20,
        fontSize: 40,
      }}
    >
      {children}
    </div>
  );
};

export default InvertedToggle;
