import { FC, ReactNode, useState } from "react";

interface Props {
  children: ReactNode;
  onClick: () => void;
}

const InvertedButton: FC<Props> = ({ children, onClick }) => {
  const [isMouseDown, setIsMouseDown] = useState(false);

  return (
    <div
      onMouseDown={() => setIsMouseDown(true)}
      onMouseUp={() => {
        setIsMouseDown(false);
        onClick();
      }}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",

        backgroundColor: isMouseDown ? "black" : "white",
        color: isMouseDown ? "white" : "black",
        border: "1px solid black",
        cursor: "pointer",
        height: 80,
        width: 200,
        padding: 20,
        fontSize: 40,
      }}
    >
      {children}
    </div>
  );
};

export default InvertedButton;
