import { useGoogleLogin } from "@react-oauth/google";
import { FC, useContext } from "react";
import AttendanceService from "../../services/attendance-service";
import InvertedButton from "../buttons/InvertedButton";
import { AppContext } from "../contexts/AppContext";

const SignInScreen: FC = () => {
  const { setAppScreen } = useContext(AppContext)!;

  const login = useGoogleLogin({
    scope: "https://www.googleapis.com/auth/spreadsheets",
    flow: "auth-code",
    onSuccess: async ({ code }) => {
      try {
        await AttendanceService.exchangeCode(code);
        setAppScreen("home");
      } catch (error) {
        console.log(error);
        alert("Could not sign in");
      }
    },
    onError: (error) => {
      console.log(error);
      alert("Could not sign in");
    },
  });

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <InvertedButton onClick={() => login()}>SIGN IN</InvertedButton>
    </div>
  );
};

export default SignInScreen;
