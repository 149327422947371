import { FC, useCallback, useContext, useState } from "react";
import { useRefresh } from "../../hooks/use-refresh";
import KaliLogo from "../../images/kali-logo.png";
import UnionLogo from "../../images/union-logo.png";
import AttendanceService from "../../services/attendance-service";
import CircleButton from "../buttons/CircleButton";
import InvertedButton from "../buttons/InvertedButton";
import { AppContext } from "../contexts/AppContext";

const HomeScreen: FC = () => {
  const { setAppScreen, isKuntao, setIsKuntao, setDirectory, setStudent } =
    useContext(AppContext)!;

  const [code, setCode] = useState("");

  useRefresh();

  const isCodeValid = code.length === 3;
  const buttonText = isCodeValid
    ? code === "000"
      ? "SIGN OUT"
      : "DONE"
    : "STUDENTS";

  const onStudentsClicked = useCallback(async () => {
    try {
      const fetchedDirectory = await AttendanceService.getDirectory();
      setDirectory(fetchedDirectory);
      setAppScreen("directory");
    } catch (error) {
      console.log(error);
      alert("Could not fetch directory");
    }
  }, [setAppScreen, setDirectory]);

  const onDoneClicked = useCallback(async () => {
    if (code === "000") {
      try {
        await AttendanceService.revoke();
      } catch (error) {
        console.log(error);
        alert("Could not sign out");
      }
      setAppScreen("sign-in");
    } else {
      try {
        const fetchedDirectory = await AttendanceService.getDirectory();
        setDirectory(fetchedDirectory);
        const student = fetchedDirectory.students[code];
        if (student) {
          try {
            await AttendanceService.updateAttendance(student, isKuntao);
            setStudent(student);
            setAppScreen("student");
          } catch (error) {
            console.log(error);
            alert("Could not update attendance");
          }
        } else {
          alert("Student not found");
        }
      } catch (error) {
        console.log(error);
        alert("Could not fetch directory");
      }
    }

    setCode("");
  }, [setDirectory, setAppScreen, setStudent, setCode, code, isKuntao]);

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <InvertedButton
          onClick={() => {
            setIsKuntao(!isKuntao);
          }}
        >
          {isKuntao ? "KUNTAO" : "KALI"}
        </InvertedButton>

        <img
          src={isKuntao ? UnionLogo : KaliLogo}
          alt={isKuntao ? "union-logo" : "kali-logo"}
          width={300}
          height={300}
        />

        <InvertedButton
          onClick={() => (isCodeValid ? onDoneClicked() : onStudentsClicked())}
        >
          {buttonText}
        </InvertedButton>
      </div>

      <div style={{ fontSize: 60 }}>STUDENT ATTENDANCE LOGIN</div>

      <div
        style={{
          display: "flex",
        }}
      >
        {Array.from(Array(3).keys()).map((i) => (
          <div key={i} style={{ margin: "0px 5px 0px 5px", fontSize: 60 }}>
            {i < code.length ? "*" : "_"}
          </div>
        ))}
      </div>

      <div
        style={{
          width: "50%",
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "space-around",
        }}
      >
        {Array.from(Array(10).keys())
          .map((i) => (i + 1) % 10)
          .map((num) => (
            <div
              key={num}
              style={{
                width: "33%",
                padding: "20px 0px 20px 0px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <CircleButton onClick={() => !isCodeValid && setCode(code + num)}>
                {num}
              </CircleButton>
            </div>
          ))}
      </div>

      <InvertedButton onClick={() => setCode("")}>CLEAR</InvertedButton>
    </div>
  );
};

export default HomeScreen;
