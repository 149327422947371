import { FC, useContext, useState } from "react";
import { useRefresh } from "../../hooks/use-refresh";
import InvertedButton from "../buttons/InvertedButton";
import InvertedToggle from "../buttons/InvertedToggle";
import { AppContext } from "../contexts/AppContext";

const DirectoryScreen: FC = () => {
  const { setAppScreen, directory } = useContext(AppContext)!;

  const [isBrampton, setIsBrampton] = useState(true);

  useRefresh();

  const location = isBrampton ? directory.brampton : directory.calgary;

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <div style={{ fontSize: 60 }}>STUDENTS</div>

      <div
        style={{
          width: "80%",
          display: "flex",
          justifyContent: "space-around",
        }}
      >
        <InvertedToggle
          isActive={isBrampton}
          onClick={() => setIsBrampton(true)}
        >
          BRAMPTON
        </InvertedToggle>
        <InvertedToggle
          isActive={!isBrampton}
          onClick={() => setIsBrampton(false)}
        >
          CALGARY
        </InvertedToggle>
      </div>

      <div style={{ height: "60%", width: "100%", overflow: "auto" }}>
        {Object.keys(location)
          .sort()
          .map((firstLetter) => {
            const students = location[firstLetter];
            return (
              <div
                key={firstLetter}
                style={{ textAlign: "center", padding: 30 }}
              >
                <div style={{ fontSize: 60 }}>{firstLetter}</div>
                {students.map(({ code, firstName, lastName }) => (
                  <div key={code} style={{ padding: 30, fontSize: 40 }}>
                    {lastName}, {firstName} - {code}
                  </div>
                ))}
              </div>
            );
          })}
      </div>

      <InvertedButton onClick={() => setAppScreen("home")}>DONE</InvertedButton>
    </div>
  );
};

export default DirectoryScreen;
