export type AppState = "loading" | "signed-out" | "signed-in";

export type AppScreen =
  | "loading"
  | "home"
  | "sign-in"
  | "student"
  | "directory";

export interface Student {
  rowNum: number;
  code: string;
  firstName: string;
  lastName: string;
  kuntaoRank: string;
  kuntaoNextStripe: string;
  kuntaoNextStripeDate: string;
  kuntaoClassesAttended: number;
  kaliRank: string;
  kaliNextStripe: string;
  kaliNextStripeDate: string;
  kaliClassesAttended: number;
  lastLoginDate: string;
  alreadyLoggedIn: boolean;
}

export interface Directory {
  brampton: Record<string, Student[]>;
  calgary: Record<string, Student[]>;
  students: Record<string, Student>;
}

export interface ExchangeCodeResponse {
  access_token: string;
  expires_in: number;
  refresh_token: string;
  scope: string;
  token_type: string;
  id_token: string;
}

export interface RefreshResponse {
  access_token: string;
  expires_in: number;
  scope: string;
  token_type: string;
  id_token: string;
}

export const REFRESH_TOKEN_KEY = "union-attendance-refresh-token";
