import moment from "moment";

const formatDate = (date: string) => {
  if (date && date.trim().toLowerCase() !== "n/a") {
    return moment(new Date(`${date}T12:00:00Z`))
      .format("dddd, MMMM DD")
      .toUpperCase();
  }
  return "N/A";
};

export { formatDate };
