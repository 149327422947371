import { FC, useContext } from "react";
import { AppContext } from "../components/contexts/AppContext";
import DirectoryScreen from "../components/screens/DirectoryScreen";
import HomeScreen from "../components/screens/HomeScreen";
import LoadingScreen from "../components/screens/LoadingScreen";
import SignInScreen from "../components/screens/SignInScreen";
import StudentScreen from "../components/screens/StudentScreen";

const AppRouter: FC = () => {
  const { appScreen } = useContext(AppContext)!;

  switch (appScreen) {
    case "home":
      return <HomeScreen />;
    case "sign-in":
      return <SignInScreen />;
    case "student":
      return <StudentScreen />;
    case "directory":
      return <DirectoryScreen />;
    case "loading":
      return <LoadingScreen />;
  }
};

export default AppRouter;
