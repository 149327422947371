import { FC, useContext, useEffect } from "react";
import { AppContext } from "../contexts/AppContext";
import AttendanceService from "../../services/attendance-service";

const LoadingScreen: FC = () => {
  const { setAppScreen } = useContext(AppContext)!;

  const refresh = async () => {
    try {
      await AttendanceService.refresh();
      setAppScreen("home");
    } catch (error) {
      console.log(error);
      await AttendanceService.revoke();
      setAppScreen("sign-in");
    }
  };

  useEffect(() => {
    refresh();
  }, []);

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        fontFamily: "Condensed",
      }}
    >
      <h1>Loading...</h1>
    </div>
  );
};

export default LoadingScreen;
