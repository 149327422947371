import { FC, ReactNode } from "react";

interface Props {
  children: ReactNode;
  onClick: () => void;
}

const CircleButton: FC<Props> = ({ children, onClick }) => {
  return (
    <div
      onClick={() => onClick()}
      style={{
        width: 150,
        height: 150,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",

        backgroundColor: "white",
        color: "black",
        border: "1px solid black",
        borderRadius: "50%",
        cursor: "pointer",

        fontFamily: "Condensed",
        fontSize: 50,
      }}
    >
      {children}
    </div>
  );
};

export default CircleButton;
