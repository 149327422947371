import { FC, useContext } from "react";
import { useRefresh } from "../../hooks/use-refresh";
import { formatDate } from "../../utils/format-date";
import InvertedButton from "../buttons/InvertedButton";
import { AppContext } from "../contexts/AppContext";

const StudentScreen: FC = () => {
  const { setAppScreen, student, isKuntao } = useContext(AppContext)!;

  useRefresh();

  const {
    firstName,
    lastName,
    kuntaoRank,
    kuntaoNextStripe,
    kuntaoNextStripeDate,
    kuntaoClassesAttended,
    kaliRank,
    kaliNextStripe,
    kaliNextStripeDate,
    kaliClassesAttended,
    alreadyLoggedIn,
  } = student!;

  const [rank, nextStripe, nextStripeDate, classesAttended, beltOrSash, color] =
    isKuntao
      ? [
          kuntaoRank,
          kuntaoNextStripe,
          kuntaoNextStripeDate,
          kuntaoClassesAttended,
          "BELT",
          "grey",
        ]
      : [
          kaliRank,
          kaliNextStripe,
          kaliNextStripeDate,
          kaliClassesAttended,
          "SASH",
          "red",
        ];

  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <div style={{ fontSize: 40 }}>
        {alreadyLoggedIn ? "YOU'VE ALREADY LOGGED IN TODAY" : "WELCOME"}
      </div>
      <div style={{ fontSize: 60 }}>
        {firstName} {lastName}
      </div>

      <div style={{ fontSize: 50, color }}>CURRENT RANK:</div>
      <div style={{ fontSize: 40 }}>
        {rank} {beltOrSash}
      </div>
      <div style={{ fontSize: 50, color }}>NEXT STRIPE:</div>
      <div style={{ fontSize: 40 }}>{nextStripe}</div>
      <div style={{ fontSize: 50, color }}>STRIPE DATE:</div>
      <div style={{ fontSize: 40 }}>{formatDate(nextStripeDate)}</div>
      <div style={{ fontSize: 50, color }}>CLASSES ATTENDED:</div>
      <div style={{ fontSize: 40 }}>{classesAttended} CLASSES</div>

      <InvertedButton onClick={() => setAppScreen("home")}>DONE</InvertedButton>
    </div>
  );
};

export default StudentScreen;
