import { useContext, useEffect } from "react";
import { AppContext } from "../components/contexts/AppContext";
import AttendanceService from "../services/attendance-service";

const useRefresh = () => {
  const { setAppScreen } = useContext(AppContext)!;

  const refresh = async () => {
    try {
      await AttendanceService.refresh();
    } catch (error) {
      console.log(error);
      await AttendanceService.revoke();
      alert("Refresh token has expired");
      setAppScreen("sign-in");
    }
  };

  useEffect(() => {
    refresh();
    const interval = setInterval(refresh, 1000 * 60 * 30);

    return () => {
      clearInterval(interval);
    };
  }, []);
};

export { useRefresh };
