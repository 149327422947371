import { FC } from "react";

import "./App.css";

import { GoogleOAuthProvider } from "@react-oauth/google";
import "./fonts/D-DIN-Bold.otf";
import "./fonts/D-DIN-Italic.otf";
import "./fonts/D-DIN.otf";
import "./fonts/D-DINCondensed-Bold.otf";
import "./fonts/D-DINCondensed.otf";
import "./fonts/D-DINExp-Bold.otf";
import "./fonts/D-DINExp-Italic.otf";
import "./fonts/D-DINExp.otf";
import AppRouter from "./routers/AppRouter";
import { CLIENT_ID } from "./secrets";

const App: FC = () => {
  return (
    <GoogleOAuthProvider clientId={CLIENT_ID}>
      <AppRouter />
    </GoogleOAuthProvider>
  );
};

export default App;
