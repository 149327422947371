import Axios from "axios";
import moment from "moment";
import {
  BASE_URL,
  CLIENT_ID,
  CLIENT_SECRET,
  KALI_ATTENDANCE_COL,
  KUNTAO_ATTENDANCE_COL,
  LAST_LOGIN_COL,
  REDIRECT_URL,
  STUDENTS_RANGE,
} from "../secrets";
import {
  Directory,
  ExchangeCodeResponse,
  REFRESH_TOKEN_KEY,
  RefreshResponse,
  Student,
} from "../types";

const apiClient = Axios.create({ baseURL: BASE_URL });

const AttendanceService = {
  revoke: async () => {
    try {
      const refreshToken = localStorage.getItem(REFRESH_TOKEN_KEY);
      if (refreshToken) {
        await apiClient.post("https://oauth2.googleapis.com/revoke", null, {
          params: { token: refreshToken },
        });
      }
    } catch (error) {
      console.log("Could not revoke token");
    }

    delete apiClient.defaults.headers.common["Authorization"];
    localStorage.removeItem(REFRESH_TOKEN_KEY);

    console.log("Revoked");
  },

  exchangeCode: async (code: string) => {
    const response = await Axios.post<ExchangeCodeResponse>(
      "https://oauth2.googleapis.com/token",
      null,
      {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        params: {
          code,
          client_id: CLIENT_ID,
          client_secret: CLIENT_SECRET,
          redirect_uri: REDIRECT_URL,
          grant_type: "authorization_code",
        },
      }
    );

    const { access_token: accessToken, refresh_token: refreshToken } =
      response.data;

    apiClient.defaults.headers.common[
      "Authorization"
    ] = `Bearer ${accessToken}`;
    localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);

    console.log("Exchanged code");
  },

  refresh: async () => {
    const refreshToken = localStorage.getItem(REFRESH_TOKEN_KEY);
    if (!refreshToken) {
      throw new Error("No refresh token found");
    }

    try {
      const response = await Axios.post<RefreshResponse>(
        "https://oauth2.googleapis.com/token",
        null,
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
          params: {
            client_id: CLIENT_ID,
            client_secret: CLIENT_SECRET,
            refresh_token: refreshToken,
            grant_type: "refresh_token",
          },
        }
      );

      const { access_token: accessToken } = response.data;

      apiClient.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${accessToken}`;

      console.log("Refreshed");
    } catch (error) {
      console.log("Could not refresh token");
      localStorage.removeItem(REFRESH_TOKEN_KEY);
      throw error;
    }
  },

  getDirectory: async () => {
    const response = await apiClient.get<{ values: any[][] }>(
      `/${STUDENTS_RANGE}`
    );

    const rows = response.data.values;
    const directory: Directory = { brampton: {}, calgary: {}, students: {} };
    for (let i = 0; i < rows.length; i++) {
      const rowNum = i + 2; // Offset by 2 because of header row and 0-indexing
      const rowData = rows[i];
      if (!rowData[1]) continue; // Skip empty rows

      const code = rowData[0].padStart(3, "0");
      const codeNum = parseInt(rowData[0]);
      const location = codeNum < 500 ? directory.brampton : directory.calgary;
      const [firstName, lastName] = rowData[1].split(" ");
      const firstLetter = lastName[0];
      const alreadyLoggedIn =
        rowData[10] === new Date().toISOString().split("T")[0];

      const student: Student = {
        rowNum,
        code,
        firstName,
        lastName,
        kuntaoRank: rowData[2],
        kuntaoNextStripe: rowData[3],
        kuntaoNextStripeDate: rowData[4],
        kuntaoClassesAttended: rowData[5] || 0,
        kaliRank: rowData[6],
        kaliNextStripe: rowData[7],
        kaliNextStripeDate: rowData[8],
        kaliClassesAttended: rowData[9] || 0,
        lastLoginDate: rowData[10],
        alreadyLoggedIn,
      };

      if (!location[firstLetter]) {
        location[firstLetter] = [];
      }
      location[firstLetter].push(student);
      directory.students[student.code] = student;
    }

    for (let location of [directory.brampton, directory.calgary]) {
      for (let firstLetter in location) {
        location[firstLetter].sort((a, b) =>
          a.lastName === b.lastName
            ? a.firstName.localeCompare(b.firstName)
            : a.lastName.localeCompare(b.lastName)
        );
      }
    }

    console.log("Fetched directory");

    return directory;
  },

  updateAttendance: async (student: Student, isKuntao: boolean) => {
    const today = moment(new Date()).format("YYYY-MM-DD");
    if (student.lastLoginDate === today) {
      student.alreadyLoggedIn = true;
      return;
    }

    const oldLastLoginDate = student.lastLoginDate;
    student.lastLoginDate = today;

    let path = "";
    let value = 0;
    if (isKuntao) {
      path = `/${KUNTAO_ATTENDANCE_COL}${student.rowNum}`;
      value = ++student.kuntaoClassesAttended;
    } else {
      path = `/${KALI_ATTENDANCE_COL}${student.rowNum}`;
      value = ++student.kaliClassesAttended;
    }

    try {
      await apiClient.put(
        path,
        {
          values: [[value]],
        },
        {
          params: { valueInputOption: "RAW" },
        }
      );

      path = `/${LAST_LOGIN_COL}${student.rowNum}`;

      await apiClient.put(
        path,
        {
          values: [[student.lastLoginDate]],
        },
        {
          params: { valueInputOption: "USER_ENTERED" },
        }
      );

      console.log("Updated attendance");
    } catch (error) {
      console.log("Could not update attendance");
      student.lastLoginDate = oldLastLoginDate;
      if (isKuntao) {
        value = --student.kuntaoClassesAttended;
      } else {
        value = --student.kaliClassesAttended;
      }
    }
  },
};

export default AttendanceService;
