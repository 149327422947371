import { FC, ReactNode, createContext, useState } from "react";
import { AppScreen, Directory, Student } from "../../types";

interface AppContextType {
  appScreen: AppScreen;
  setAppScreen: (screen: AppScreen) => void;
  directory: Directory;
  setDirectory: (directory: Directory) => void;
  student: Student | null;
  setStudent: (student: Student | null) => void;
  isKuntao: boolean;
  setIsKuntao: (isKuntao: boolean) => void;
}

export const AppContext = createContext<AppContextType | null>(null);

interface Props {
  children: ReactNode;
}

const AppContextProvider: FC<Props> = ({ children }) => {
  const [appScreen, setAppScreen] = useState<AppScreen>("loading");
  const [directory, setDirectory] = useState<Directory>({
    brampton: {},
    calgary: {},
    students: {},
  });
  const [student, setStudent] = useState<Student | null>(null);
  const [isKuntao, setIsKuntao] = useState(true);

  return (
    <AppContext.Provider
      value={{
        appScreen,
        setAppScreen,
        directory,
        setDirectory,
        student,
        setStudent,
        isKuntao,
        setIsKuntao,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppContextProvider;
