import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import AppContextProvider from "./components/contexts/AppContext";

import "./index.css";

const tabletWidth = 1200;
const tabletHeight = 1750;
const browserScalingFactor = 1;

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <AppContextProvider>
      <div
        style={{
          width: tabletWidth * browserScalingFactor,
          height: tabletHeight * browserScalingFactor,
          padding: 100,
          border: "1px solid black",
        }}
      >
        <App />
      </div>
    </AppContextProvider>
  </React.StrictMode>
);
